import React, { useEffect } from 'react'
import { Link, navigate } from 'gatsby'
import { Formik, Form, Field } from 'formik'
import { useQueryClient } from 'react-query'

import isBrowser from '../validators/isBrowser'

import { useAuth } from '../contexts/auth'
import { useNotifications } from '../contexts/notifications'

import GoogleAuthButton from '../components/googleAuthButton'

const Login = () => {
    const {
        authService,
        authState
    } = useAuth()

    const {
        dispatch
    } = useNotifications()

    const queryClient = useQueryClient()

    const handleSubmit = async (data, { setSubmitting, resetForm }) => {
        setSubmitting(true)

        try {
            await authService.login( data.email, data.password )

            resetForm()
            setSubmitting(false)
            navigate('/app')
        } catch (e) {
            dispatch({
                type: "ADD_ERROR",
                payload: e.message
            })

            resetForm()
            setSubmitting(false)
        }
    }
    
    useEffect(() => {
        if ( !isBrowser() ) return () => {}

        queryClient.invalidateQueries()

        if ( authState.token ) {
            navigate('/app')
        }
    }, [ authState, queryClient ])
    return (
        <div className="bg-gray-50">
            <div className="container">
                <div className="min-h-screen flex flex-col justify-center py-12 sm:px-6 lg:px-8">
                    <div className="sm:mx-auto sm:w-full sm:max-w-md">
                        <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">Sign in to your account</h2>
                    </div>
                    <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-sm">
                        <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
                            <Formik
                                initialValues={{
                                    email: ``,
                                    password: ``,
                                }}
                                onSubmit={handleSubmit}
                            >
                                {({
                                    isSubmitting,
                                }) => (
                                    <Form className="grid gap-y-4">
                                        <GoogleAuthButton
                                            handleError={(message) => dispatch({
                                                type: "ADD_ERROR",
                                                payload: message
                                            })}
                                            placeholder="Continue with Google" 
                                        />
                                        <div className="relative">
                                            <div className="absolute inset-0 flex items-center">
                                                <div className="w-full border-t border-gray-300" />
                                            </div>
                                            <div className="relative flex justify-center text-sm">
                                                <span className="px-2 bg-white text-gray-500">Or sign in with your email</span>
                                            </div>
                                        </div>                            
                                        <div>
                                            <label htmlFor="email" className="block text-sm font-medium text-gray-700">
                                                Email address
                                            </label>
                                            <div className="mt-1">
                                            <Field
                                                id="email"
                                                name="email"
                                                type="email"
                                                autoComplete="email"
                                                disabled={isSubmitting}
                                                required
                                                className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-black focus:border-black sm:text-sm"
                                            />
                                            </div>
                                        </div>
                                        <div>
                                            <label htmlFor="password" className="block text-sm font-medium text-gray-700">
                                                Password
                                            </label>
                                            <div className="mt-1">
                                                <Field
                                                    id="password"
                                                    name="password"
                                                    type="password"
                                                    autoComplete="current-password"
                                                    disabled={isSubmitting}
                                                    required
                                                    className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-black focus:border-black sm:text-sm"
                                                />
                                            </div>
                                        </div>
                                        <div>
                                            <Link className="text-black font-bold text-sm" to="/reset-password">
                                                Forgot your password?
                                            </Link>
                                        </div>
                                        <div>
                                            <button
                                                type="submit"
                                                className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-black hover:bg-opacity-80 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-black"
                                            >
                                                {isSubmitting ?
                                                    <svg className="w-5 h-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100" preserveAspectRatio="xMidYMid">
                                                        <circle cx="50" cy="50" fill="none" stroke="currentColor" strokeWidth="10" r="35" strokeDasharray="164.93361431346415 56.97787143782138">
                                                            <animateTransform attributeName="transform" type="rotate" repeatCount="indefinite" dur="1s" values="0 50 50;360 50 50" keyTimes="0;1"/>
                                                        </circle>
                                                    </svg>
                                                :
                                                    `Sign In`
                                                }
                                            </button>
                                        </div>
                                    </Form>
                                )}
                            </Formik>
                        </div>
                        <Link to={`/register`} className="w-full inline-block mt-6 text-sm text-center text-gray-600">
                            <span>Don't have an account yet? </span>
                            <span className="text-black font-bold">Get started here</span>
                        </Link>
                    </div>
                </div>
            </div>
        </div>
    )
} 

export default Login