import React from 'react'

import Login from '../templates/login'

import AuthComponent from '../components/authComponent'
import Layout from '../components/layout'

const Home = ({
    location
}) => {
    return (
        <AuthComponent> 
            <Layout>
                <Login/>
            </Layout>
        </AuthComponent>
    )
}

export default Home